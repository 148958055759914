const RELAYS = [
  // 'ws://localhost:7000'
  // 'ws://127.0.0.1:4444'
  'wss://relay.damus.io/'
  //   'wss://relay.snort.social'
  //   'wss://purplepag.es',
  //   'wss://nos.lol/',
  //   'wss://relay-jp.nostr.wirednet.jp/',
  //   'wss://nostr-relay.nokotaro.com/',
  //   'wss://nostr.holybea.com/',
  //   'wss://nostr.fediverse.jp/',
  //   'wss://yabu.me/'
]
export default RELAYS
